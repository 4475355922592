import * as React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Header from '../components/Header/Header';
import Layout from '../components/Layout';
import PortfolioAlbum from '../components/PortfolioAlbum/PortfolioAlbum';
import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs';
import { LineIcon } from '../images/index';
import * as styles from '../components/Header/header.module.scss';

const AlbumPageTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const intl = useIntl();
  const langData = intl.locale === 'hu' ? frontmatter.hu : frontmatter.en;
  const crumbs = ['home', 'enterior', 'portfolio', langData.title];

  return (
    <Layout>
      <Header className={styles.bcgColor}>
        {langData.heading.split('\n\n').map((line, index) => (
          <h1 className={styles.headerTitle} key={index}>
            {line}
          </h1>
        ))}
        <LineIcon className={styles.lineIcon} />
      </Header>
      <BreadCrumbs crumbs={crumbs} className="bg-grey" />
      <PortfolioAlbum album={frontmatter} />
    </Layout>
  );
};

export default AlbumPageTemplate;

export const pageQuery = graphql`
  query AlbumPageTemplateQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hu {
          heading
          title
          author
          images {
            image {
              childImageSharp {
                gatsbyImageData(width: 2048)
              }
            }
            imageAlt
          }
        }
        en {
          heading
          title
          author
        }
      }
    }
  }
`;
