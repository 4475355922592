import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import c from 'classnames';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import StackGrid, { transitions } from 'react-stack-grid';
import { Col, Container, Row } from 'react-bootstrap';
import * as styles from './album.module.scss';

const PortfolioAlbum = ({ album }) => {
  const { scaleDown } = transitions;
  const intl = useIntl();
  const options = {
    caption: {
      showCaption: false
    }
  };

  return (
    <Container fluid className={styles.albumContainer}>
      <Container>
        <Row>
          <Col className={c(styles.column, 'text-center')}>
            <h3 className="title">{album.hu.title}</h3>
            <p className={styles.author}>
              {intl.formatMessage({ id: 'main.album.photo' })}:{' '}
              {album.hu.author}
            </p>
          </Col>
        </Row>
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            <StackGrid
              columnWidth={300}
              gutterWidth={20}
              gutterHeight={20}
              appear={scaleDown.appear}
              appeared={scaleDown.appeared}
              enter={scaleDown.enter}
              entered={scaleDown.entered}
              leaved={scaleDown.leaved}
              monitorImagesLoaded={true}
            >
              {album.hu.images.map((image, index) => (
                <GatsbyImage
                  key={index}
                  image={getImage(image.image)}
                  alt={image.alt}
                />
              ))}
            </StackGrid>
          </SRLWrapper>
        </SimpleReactLightbox>
      </Container>
    </Container>
  );
};

export default PortfolioAlbum;
